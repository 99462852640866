export const ALL_LANGUAGES = ['en-US', 'de']; // equal to the browser native language abbreviations

export type Language = typeof ALL_LANGUAGES[number];

export type LanguageData = {
    languageNames: string[];
    components: {
        hero: {
            heading: string;
            subHeading: string;
        };
        logo: {
            upperText: string;
            lowerText: string;
        };
        landing: {
            getInTouch: string;
        };
        about: {
            title: string;
            aboutParagraph: string | JSX.Element;
            birth: string;
            languages: string;
            leisure: string;
            programming: string;
            libraries: string;
        };
        experiences: {
            title: string;
            work: {
                title: string;
                steps: {
                    period: string;
                    headline: string;
                    position: string;
                    paragraph: JSX.Element | JSX.Element[] | string;
                }[];
            };
            education: {
                title: string;
                steps: {
                    period: string;
                    headline: string;
                    degree: string;
                    paragraph: JSX.Element | JSX.Element[] | string;
                }[];
            };
        };
        projects: {
            title: string;
            sections: {
                title: string;
                steps: {
                    headline: string;
                    icon: string; // octicon icon
                    iconText: string;
                    links: { name: string; href: string }[];
                    tags: string[];
                    content: string | JSX.Element | JSX.Element[];
                }[];
            }[];
        };
        contact: {
            title: string;
            requiredError: string;
            name: {
                label: string;
                placeholder: string;
            };
            email: {
                label: string;
                placeholder: string;
                wrongFormatError: string;
            };
            message: {
                label: string;
                placeholder: string;
            };
            privacyPolicy: string;
            submitButtonLabel: string;
        };
        snackbar: {
            errors: {
                formDataSubmissionFailed: string;
            };
            successes: {
                formDataSubmissionSucceeded: string;
            };
        };
        footer: {
            links: { name: string; href: string }[];
        };
        dataProtection: {
            back: string;
            title: string;
            content: string | JSX.Element | JSX.Element[];
        };
        cookieNotice: {
            noticeText: string;
            accept: string;
            acceptAll: string;
            readMore: string;
            requiredCookiesDescription: string;
            googleAnalyticsDescription?: string; // only needed if enabled
        };
    };
};

export const LANGUAGE_DATA: { [key in Language]: LanguageData } = {
    'en-US': {
        languageNames: ['EN', 'DE'],
        components: {
            hero: {
                heading: 'Florian Franke',
                subHeading: 'Data Scientist. Postgraduate. Rationalist.',
            },
            cookieNotice: {
                accept: 'Accept',
                acceptAll: 'Accept all',
                readMore: 'Read more',
                noticeText: 'This website uses cookies to improve your experience.',
                requiredCookiesDescription: 'Required cookies for the delivery of the website',
                googleAnalyticsDescription: 'Cookies for improvements of the content',
            },
            logo: {
                upperText: 'Florian',
                lowerText: 'Franke',
            },
            landing: {
                getInTouch: 'Get in touch',
            },
            about: {
                title: 'About',
                aboutParagraph: (
                    <>
                        Hi, I&apos;m Florian, Data Scientist and PhD student from Dresden. I love to tackle new
                        challenges in my professional and private life and to find solutions for various problems with
                        the help of modern technologies.
                    </>
                ),
                birth: 'birth',
                languages: 'languages',
                leisure: 'leisure',
                programming: 'programming',
                libraries: 'libraries and frameworks',
            },
            experiences: {
                title: 'Experiences',
                education: {
                    title: 'Education',
                    steps: [
                        {
                            degree: 'PhD',
                            headline: 'HTW Dresden',
                            paragraph: (
                                <>
                                    In the context of my dissertation, at the HTW Dresden in cooperation with the TU
                                    Freiberg, I am working on the mathematical modeling and simulation of un- and
                                    healthy biological tissues. My goals are on the one hand to get a better
                                    understanding of the tissue formation of healthy cells using a cellular automaton.
                                    For example, in embryonic formation it is still not fully understood why cells of
                                    different types separate and cells of the same type form groups and finally an
                                    organ. On the other hand, my goal is to contribute to the improvement of radiation
                                    therapy of tumors. My approach is to use rotational symmetry of 3D tumor spheroids
                                    to set up a 1D differential equation system.
                                </>
                            ),
                            period: '11/2020 - now',
                        },
                        {
                            degree: 'Master of Science',
                            headline: 'HTW Dresden',
                            paragraph: (
                                <>
                                    I completed my master&apos;s degree at the HTW Dresden in the field of applied
                                    computer science with the specialization in Data Science. This field of study also
                                    covered topics in the direction of simulation and machine learning methods were
                                    covered in more detail, which is why my master&apos;s thesis master thesis with the
                                    topic &quot;Do cells segregate like oil and water: A phase field approach? a
                                    mathematical model&quot; for the simulation of phase fields, based on the
                                    Cahn-Hilliard equations, and developed a mathematical model for the simulation of
                                    phase fields.
                                </>
                            ),
                            period: '10/2018 - 10/2020',
                        },
                        {
                            degree: 'Bachelor of Engineering',
                            headline: 'BA Sachsen',
                            paragraph: (
                                <>
                                    During my dual studies at the BA Dresden in the field of information technology, I
                                    gained an in-depth deeper insight into information technology and electrical
                                    engineering subjects. During the practical phases I worked full time for Kubus IT,
                                    where I got to know the different topics of computer science in different areas.
                                </>
                            ),
                            period: '10/2015 - 09/2018',
                        },
                        {
                            degree: 'General qualification for university entrance',
                            headline: 'Martin-Andersen-Nexö Gymnasium',
                            paragraph: (
                                <>
                                    I spent my high school years at the Martin-Andersen-Nexö Gymnasium in Dresden. Here
                                    I learned general higher education entrance qualification with the advanced courses
                                    in mathematics, physics and chemistry. Additionally I chose the subject subject
                                    computer science, because I already felt a great enthusiasm in this area early on.
                                    But since I was also interested in scientific work, I decided to do a BeLL
                                    (Besondere Lernleistung; scientific work with defense) with the help of the work
                                    with defense), with the help of the Fraunhofer IVI, into my Abitur.
                                </>
                            ),
                            period: '08/2007 - 07/2015',
                        },
                    ],
                },
                work: {
                    title: 'Work',
                    steps: [
                        {
                            position: 'Scientific Assistant',
                            headline: 'HTW Dresden',
                            paragraph: (
                                <>
                                    In the{' '}
                                    <a
                                        href="https://www.htw-dresden.de/hochschule/fakultaeten/info-math/forschung/zellbasierte-analyse-in-der-systemmedizin"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        DataMedAssist group
                                    </a>
                                    , I am part of an interdisciplinary team consisting of PhD and doctoral researchers
                                    in biology, physics, and mathematics. Together, we aim to advance research in
                                    cell-based analysis in the field of systems medicine. At the same time, I support
                                    the university in teaching. Parts of my research results flow into my PhD thesis,
                                    which I am working on in parallel.
                                </>
                            ),
                            period: '03/2020 - now',
                        },
                        {
                            position: 'Student Assistant',
                            headline: 'HTW Dresden',
                            paragraph: (
                                <>
                                    During my time in the research group of Prof. Hans-Joachim Böhme, I helped to
                                    evaluate different models for Speech-To-Text for the German language. It was an
                                    exciting time in which I was able to further develop my skills and knowledge in the
                                    field of machine learning. In the end, my experience and and enthusiasm for the
                                    topic led me to privately build my own smart home with speech control, where the
                                    Speech-To-Text function is offline capable.
                                </>
                            ),
                            period: '02/2019 - 02/2020',
                        },
                        {
                            position: 'Student Assistant',
                            headline: 'Fraunhofer IVI',
                            paragraph: (
                                <>
                                    At the Fraunhofer IVI, I was part of a research project in which I investigated
                                    different approaches to setting up a configuration management system and the secure
                                    storage of shared secrets within a company. configuration management system and the
                                    secure storage of shared secrets within a system. system. In the end, I also set up
                                    a Vault within the given infrastructure together with Spring which was used for
                                    authentication in the project.
                                </>
                            ),
                            period: '10/2018 - 02/2019',
                        },
                        {
                            position: 'Dual Student',
                            headline: 'Kubus IT',
                            paragraph: (
                                <>
                                    kubusIT is the IT service provider for AOK PLUS and Bavaria. As a result, it was
                                    possible for me to get a broad into the world of computer science and to experience
                                    many topics practically. Key points of my work were databases (MSDB), firewalls, IT
                                    security and many more.
                                </>
                            ),
                            period: '10/2015 - 09/2018',
                        },
                        {
                            position: 'Intern',
                            headline: 'Kubus IT',
                            paragraph: (
                                <>
                                    In my compulsory internship in the 10th grade, I deliberately chose an IT company,
                                    in order to get closer to my urge to to dive completely into the IT world. In doing
                                    so, I could only strengthen my desire to go deeper into IT, only strengthened.
                                </>
                            ),
                            period: '04/2011',
                        },
                    ],
                },
            },
            contact: {
                title: 'Contact',
                requiredError: 'This field is required',
                email: {
                    label: 'Email',
                    placeholder: 'Your Email *',
                    wrongFormatError: 'Enter a valid email address!',
                },
                message: {
                    label: 'Message',
                    placeholder: 'Your Message... *',
                },
                name: {
                    label: 'Name',
                    placeholder: 'Your Name *',
                },
                privacyPolicy: 'I agree to the privacy policy.',
                submitButtonLabel: 'Submit',
            },
            projects: {
                title: 'Projects & Publications',
                sections: [
                    {
                        steps: [
			    {
                                content: (
                                    <>
					This project loads your transactions invoices and calculates your unique
					portfolio statistics, performance and compares it with your personal choice of
					stock. Currently supported trading platforms are Trade Republic, Bison, and
					scalable Capital (testes partially)
                                    </>
                                ),
                                headline: 'Portfolio Analysis',
                                icon: 'finance',
                                iconText: '',
                                links: [
                                    {
                                        name: 'Link to GitLab',
                                        href: 'https://gitlab.florian-franke.eu/florian/portfolioanalysis',
                                    },
                                ],
                                tags: ['python', 'REST API'],
                            },
                            {
                                content: (
                                    <>
                                        This project includes an application that, based on real stock market data,
                                        recommends recommends action strategies for stock trading. These are published
                                        via chat messages by a Telegram bot that can be subscribed to.
                                    </>
                                ),
                                headline: 'autoTrade - Trading Consultant Bot',
                                icon: 'finance',
                                iconText: '',
                                links: [
                                    {
                                        name: 'Link to GitLab',
                                        href: 'https://gitlab.florian-franke.eu/florian/autotrade',
                                    },
                                ],
                                tags: ['python', 'REST API'],
                            },
                            {
                                content: (
                                    <>
                                        In this IoT project, I developed a voice assistant that runs on a Raspberry Pi
                                        and accepts voice commands. These are then evaluated in server-side Python
                                        software and corresponding actions are triggered, such as turning on a light or
                                        setting a timer. The voice assistant is also able to provide corresponding voice
                                        outputs. The special feature is that, apart from a few skills, the system can be
                                        operated completely independently of the Internet.
                                    </>
                                ),
                                headline: 'Home Assistent - Offline Capable Home Assistant',
                                icon: 'iot',
                                iconText: '',
                                links: [
                                    {
                                        name: 'Link to GitLab',
                                        href: 'https://gitlab.florian-franke.eu/florian/home-assistent',
                                    },
                                ],
                                tags: ['python', 'MQTT', 'IoT', 'Smart Home'],
                            },
                            {
                                content: (
                                    <>
                                        This is a tool that visualizes the pipelining of various assembler instructions
                                        in the processor. This project originated from my time at BA-Dresden, where we
                                        were taught the process of pipelining the instructions in assembler. Here I had
                                        so much fun with assembler and the theory that I can back it up with a small
                                        practical project.
                                    </>
                                ),
                                headline: 'Assembler Pipeline',
                                icon: 'flow',
                                iconText: '',
                                links: [
                                    {
                                        name: 'Link to GitLab',
                                        href: 'https://gitlab.florian-franke.eu/florian/asspipe',
                                    },
                                ],
                                tags: ['python', 'Assembler', 'Flask'],
                            },
                        ],
                        title: 'Projects',
                    },
                    {
                        steps: [
                            {
                                content: (
                                    <>
                                        <p>
                                            Florian Franke, Sebastian Aland, Hans-Joachim Böhme, Anja Voss-Böhme,
                                            Steffen Lange
                                        </p>
                                        <p>November 2021</p>
                                    </>
                                ),
                                headline: 'Is cell segregation like oil and water: asymptotic versus transitory regime',
                                icon: 'book',
                                iconText: 'Article',
                                links: [{ name: 'Link zum Magazin', href: 'https://arxiv.org/abs/2109.00364' }],
                                tags: [
                                    'under peer review',
                                    'Cahn-Hilliard',
                                    'Cellular Automaton',
                                    'Cells',
                                    'segregation',
                                    'Python',
                                    'C++',
                                ],
                            },
                            {
                                content: (
                                    <>
                                        <p>Nadja Adam, Florian Franke, Sebastian Aland</p>
                                        <p>July 2020</p>
                                    </>
                                ),
                                headline:
                                    'A Simple Parallel Solution Method for the Navier-Stokes Cahn-Hilliard Equations ',
                                icon: 'book',
                                iconText: 'Article',
                                links: [{ name: 'link to magazine', href: 'https://doi.org/10.3390/math8081224' }],
                                tags: ['Navier-Stokes', 'Cahn-Hilliard', 'C++', 'OpenMP', 'MPI'],
                            },
                            {
                                content: 'September 2020',
                                headline: 'Entmischen sich Zellen genauso wie Öl und Wasser: Ein Phasenfeld-Ansatz',
                                icon: 'book',
                                iconText: 'Master Thesis',
                                links: [
                                    {
                                        name: 'Download',
                                        href: 'https://cloud.florian-franke.eu/index.php/s/zd7SRdKWSgf2ixp',
                                    },
                                ],
                                tags: ['Cellular Automotion', 'Navier-Stokes', 'Cahn-Hilliard', 'C++', 'python'],
                            },
                            {
                                content: 'July 2018',
                                headline:
                                    'Prototypische Entwicklung eines modularen Frameworks zum automatisierten Anlegen einer Demilitarisierten Zone in einem mittelständischen Unternehmen',
                                icon: 'book',
                                iconText: 'Bachelor Thesis',
                                links: [],
                                tags: ['Firewall', 'Powershell', 'Networking'],
                            },
                        ],
                        title: 'Publications',
                    },
                ],
            },
            snackbar: {
                errors: {
                    formDataSubmissionFailed: 'Error while submitting your data. Please try again later.',
                },
                successes: {
                    formDataSubmissionSucceeded:
                        'Your information was submitted. You will receive a confirmation email.',
                },
            },
            footer: {
                links: [],
            },
            dataProtection: {
                back: 'Back',
                title: 'Data Protection',
                content: (
                    <>
                        <h2>Privacy Policy</h2>{' '}
                        <p>
                            Personal data (usually referred to just as &quot;data&quot; below) will only be processed by
                            us to the extent necessary and for the purpose of providing a functional and user-friendly
                            website, including its contents, and the services offered there.
                        </p>
                        <p>
                            Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection Regulation
                            (hereinafter referred to as the &quot;GDPR&quot;), &quot;processing&quot; refers to any
                            operation or set of operations such as collection, recording, organization, structuring,
                            storage, adaptation, alteration, retrieval, consultation, use, disclosure by transmission,
                            dissemination, or otherwise making available, alignment, or combination, restriction,
                            erasure, or destruction performed on personal data, whether by automated means or not.
                        </p>{' '}
                        <p>
                            The following privacy policy is intended to inform you in particular about the type, scope,
                            purpose, duration, and legal basis for the processing of such data either under our own
                            control or in conjunction with others. We also inform you below about the third-party
                            components we use to optimize our website and improve the user experience which may result
                            in said third parties also processing data they collect and control.
                        </p>{' '}
                        <p>Our privacy policy is structured as follows:</p>{' '}
                        <p>
                            I. Information about us as controllers of your data
                            <br />
                            II. The rights of users and data subjects
                            <br />
                            III. Information about the data processing
                        </p>{' '}
                        <h3>I. Information about us as controllers of your data</h3>{' '}
                        <p>
                            The party responsible for this website (the &quot;controller&quot;) for purposes of data
                            protection law is:
                        </p>{' '}
                        <p>
                            <span>Florian Franke</span>
                            <br />
                            <span>mail@florian-franke.eu</span>
                            <br />
                            <span>Germany</span>
                        </p>{' '}
                        <h3>II. The rights of users and data subjects</h3>{' '}
                        <p>
                            With regard to the data processing to be described in more detail below, users and data
                            subjects have the right
                        </p>{' '}
                        <ul>
                            {' '}
                            <li>
                                to confirmation of whether data concerning them is being processed, information about
                                the data being processed, further information about the nature of the data processing,
                                and copies of the data (cf. also Art. 15 GDPR);
                            </li>{' '}
                            <li>to correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);</li>
                            <li>
                                to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO), or,
                                alternatively, if further processing is necessary as stipulated in Art. 17 Para. 3 GDPR,
                                to restrict said processing per Art. 18 GDPR;
                            </li>{' '}
                            <li>
                                to receive copies of the data concerning them and/or provided by them and to have the
                                same transmitted to other providers/controllers (cf. also Art. 20 GDPR);
                            </li>{' '}
                            <li>
                                to file complaints with the supervisory authority if they believe that data concerning
                                them is being processed by the controller in breach of data protection provisions (see
                                also Art. 77 GDPR).
                            </li>{' '}
                        </ul>{' '}
                        <p>
                            In addition, the controller is obliged to inform all recipients to whom it discloses data of
                            any such corrections, deletions, or restrictions placed on processing the same per Art. 16,
                            17 Para. 1, 18 GDPR. However, this obligation does not apply if such notification is
                            impossible or involves a disproportionate effort. Nevertheless, users have a right to
                            information about these recipients.
                        </p>{' '}
                        <p>
                            <strong>
                                Likewise, under Art. 21 GDPR, users and data subjects have the right to object to the
                                controller&apos;s future processing of their data pursuant to Art. 6 Para. 1 lit. f)
                                GDPR. In particular, an objection to data processing for the purpose of direct
                                advertising is permissible.
                            </strong>
                        </p>{' '}
                        <h3>III. Information about the data processing</h3>{' '}
                        <p>
                            Your data processed when using our website will be deleted or blocked as soon as the purpose
                            for its storage ceases to apply, provided the deletion of the same is not in breach of any
                            statutory storage obligations or unless otherwise stipulated below.
                        </p>{' '}
                        <h4>Server data</h4>{' '}
                        <p>
                            For technical reasons, the following data sent by your internet browser to us or to our
                            server provider will be collected, especially to ensure a secure and stable website: These
                            server log files record the type and version of your browser, operating system, the website
                            from which you came (referrer URL), the webpages on our site visited, the date and time of
                            your visit, as well as the IP address from which you visited our site.
                        </p>{' '}
                        <p>
                            The data thus collected will be temporarily stored, but not in association with any other of
                            your data.
                        </p>{' '}
                        <p>
                            The basis for this storage is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in
                            the improvement, stability, functionality, and security of our website.
                        </p>{' '}
                        <p>
                            The data will be deleted within no more than seven days, unless continued storage is
                            required for evidentiary purposes. In which case, all or part of the data will be excluded
                            from deletion until the investigation of the relevant incident is finally resolved.
                        </p>{' '}
                        <h4>Cookies</h4> <h5>a) Session cookies</h5>{' '}
                        <p>
                            We use cookies on our website. Cookies are small text files or other storage technologies
                            stored on your computer by your browser. These cookies process certain specific information
                            about you, such as your browser, location data, or IP address. &nbsp;
                        </p>{' '}
                        <p>
                            This processing makes our website more user-friendly, efficient, and secure, allowing us,
                            for example, to display our website in different languages or to offer a shopping cart
                            function.
                        </p>{' '}
                        <p>
                            The legal basis for such processing is Art. 6 Para. 1 lit. b) GDPR, insofar as these cookies
                            are used to collect data to initiate or process contractual relationships.
                        </p>{' '}
                        <p>
                            If the processing does not serve to initiate or process a contract, our legitimate interest
                            lies in improving the functionality of our website. The legal basis is then Art. 6 Para. 1
                            lit. f) GDPR.
                        </p>{' '}
                        <p>When you close your browser, these session cookies are deleted.</p>{' '}
                        <h5>b) Third-party cookies</h5>{' '}
                        <p>
                            If necessary, our website may also use cookies from companies with whom we cooperate for the
                            purpose of advertising, analyzing, or improving the features of our website.
                        </p>{' '}
                        <p>
                            Please refer to the following information for details, in particular for the legal basis and
                            purpose of such third-party collection and processing of data collected through cookies.
                        </p>{' '}
                        <h5>c) Disabling cookies</h5>{' '}
                        <p>
                            You can refuse the use of cookies by changing the settings on your browser. Likewise, you
                            can use the browser to delete cookies that have already been stored. However, the steps and
                            measures required vary, depending on the browser you use. If you have any questions, please
                            use the help function or consult the documentation for your browser or contact its maker for
                            support. Browser settings cannot prevent so-called flash cookies from being set. Instead,
                            you will need to change the setting of your Flash player. The steps and measures required
                            for this also depend on the Flash player you are using. If you have any questions, please
                            use the help function or consult the documentation for your Flash player or contact its
                            maker for support.
                        </p>{' '}
                        <p>
                            If you prevent or restrict the installation of cookies, not all of the functions on our site
                            may be fully usable.
                        </p>{' '}
                        <h4>Contact</h4>{' '}
                        <p>
                            If you contact us via email or the contact form, the data you provide will be used for the
                            purpose of processing your request. We must have this data in order to process and answer
                            your inquiry; otherwise we will not be able to answer it in full or at all.
                        </p>{' '}
                        <p>The legal basis for this data processing is Art. 6 Para. 1 lit. b) GDPR.</p>{' '}
                        <p>
                            Your data will be deleted once we have fully answered your inquiry and there is no further
                            legal obligation to store your data, such as if an order or contract resulted therefrom.
                        </p>{' '}
                        <h4>Google Analytics</h4>{' '}
                        <p>
                            We use Google Analytics on our website. This is a web analytics service provided by Google
                            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (hereinafter: Google).
                        </p>{' '}
                        <p>
                            The Google Analytics service is used to analyze how our website is used. The legal basis is
                            Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the analysis, optimization, and
                            economic operation of our site.
                        </p>{' '}
                        <p>
                            Usage and user-related information, such as IP address, place, time, or frequency of your
                            visits to our website will be transmitted to a Google server in the United States and stored
                            there. However, we use Google Analytics with the so-called anonymization function, whereby
                            Google truncates the IP address within the EU or the EEA before it is transmitted to the US.
                        </p>{' '}
                        <p>
                            The data collected in this way is in turn used by Google to provide us with an evaluation of
                            visits to our website and what visitors do once there. This data can also be used to provide
                            other services related to the use of our website and of the internet in general.
                        </p>{' '}
                        <p>
                            Google states that it will not connect your IP address to other data. In addition, Google
                            provides further information with regard to its data protection practices at
                        </p>{' '}
                        <p>
                            <a
                                href="https://www.google.com/intl/de/policies/privacy/partners"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://www.google.com/intl/de/policies/privacy/partners,
                            </a>
                        </p>
                        <p>including options you can exercise to prevent such use of your data.</p>{' '}
                        <p>In addition, Google offers an opt-out add-on at</p>{' '}
                        <p>
                            <a
                                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://tools.google.com/dlpage/gaoptout?hl=en
                            </a>
                        </p>
                        <p>
                            in addition with further information. This add-on can be installed on the most popular
                            browsers and offers you further control over the data that Google collects when you visit
                            our website. The add-on informs Google Analytics&apos; JavaScript (ga.js) that no
                            information about the website visit should be transmitted to Google Analytics. However, this
                            does not prevent information from being transmitted to us or to other web analytics services
                            we may use as detailed herein.
                        </p>{' '}
                        <h4>Google reCAPTCHA</h4>{' '}
                        <p>
                            Our website uses Google reCAPTCHA to check and prevent automated servers (&quot;bots&quot;)
                            from accessing and interacting with our website. This is a service provided by Google
                            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (hereinafter: Google).
                        </p>{' '}
                        <p>
                            This service allows Google to determine from which website your request has been sent and
                            from which IP address the reCAPTCHA input box has been used. In addition to your IP address,
                            Google may collect other information necessary to provide and guarantee this
                            service.&nbsp;&nbsp;&nbsp;
                        </p>{' '}
                        <p>
                            The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the security
                            of our website and in the prevention of unwanted, automated access in the form of spam or
                            similar.
                        </p>{' '}
                        <p>Google offers detailed information at</p>{' '}
                        <p>
                            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                                https://policies.google.com/privacy
                            </a>
                        </p>{' '}
                        <p>concerning the general handling of your user data.</p> <h4>Google Fonts</h4>{' '}
                        <p>
                            Our website uses Google Fonts to display external fonts. This is a service provided by
                            Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (hereinafter: Google).
                        </p>{' '}
                        <p>
                            To enable the display of certain fonts on our website, a connection to the Google server in
                            the USA is established whenever our website is accessed.
                        </p>{' '}
                        <p>
                            The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the
                            optimization and economic operation of our site.
                        </p>{' '}
                        <p>
                            When you access our site, a connection to Google is established from which Google can
                            identify the site from which your request has been sent and to which IP address the fonts
                            are being transmitted for display.
                        </p>{' '}
                        <p>Google offers detailed information at</p>{' '}
                        <p>
                            <a
                                href="https://adssettings.google.com/authenticated"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://adssettings.google.com/authenticated
                            </a>
                        </p>{' '}
                        <p>
                            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                                https://policies.google.com/privacy
                            </a>
                        </p>{' '}
                        <p>in particular on options for preventing the use of data.</p>{' '}
                        <p>
                            <a
                                href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Model Data Protection Statement
                            </a>{' '}
                            for{' '}
                            <a href="https://www.ratgeberrecht.eu/" target="_blank" rel="noopener noreferrer">
                                Anwaltskanzlei Weiß &amp; Partner
                            </a>
                        </p>
                    </>
                ),
            },
        },
    },
    de: {
        languageNames: ['EN', 'DE'],
        components: {
            hero: {
                heading: 'Florian Franke',
                subHeading: 'Data Scientist. Doktorand. Rationalist.',
            },
            cookieNotice: {
                accept: 'Akzeptieren',
                acceptAll: 'Alle akzeptieren',
                readMore: 'Mehr erfahren',
                noticeText: 'Diese Webseite verwendet Cookies, um Ihr Nutzererlebnis zu optimieren.',
                requiredCookiesDescription: 'Erforderliche Cookies zur Bereitstellung der Webseite',
                googleAnalyticsDescription: 'Cookies zur Verbesserung der Inhalte',
            },
            logo: {
                upperText: 'Florian',
                lowerText: 'Franke',
            },
            landing: {
                getInTouch: 'Kontaktieren Sie mich',
            },
            about: {
                title: 'Über mich',
                aboutParagraph: (
                    <>
                        Hi, ich bin Florian, Data Scientist und Doktorand aus Dresden. Ich liebe es, neue
                        Herausforderungen in meinem beruflichen und privaten Leben anzugehen und mit Hilfe moderner
                        Technologien Lösungen für verschiedenste Probleme zu finden.
                    </>
                ),
                birth: 'Geburtstag',
                languages: 'Sprachen',
                leisure: 'Freizeit',
                programming: 'Programmierung',
                libraries: 'Bibliotheken und Frameworks',
            },
            experiences: {
                title: 'Erfahrung',
                education: {
                    title: 'Bildungsweg',
                    steps: [
                        {
                            degree: 'Doktor',
                            headline: 'HTW Dresden',
                            paragraph: (
                                <>
                                    Im Rahmen meiner Dissertation, an der HTW Dresden in Kooperation mit der TU
                                    Freiberg, beschäftige ich mich mit der mathematischen Modelleierung und Simulation
                                    von un- und gesunden biologischem Gewebe. Meine Ziele sind zum einen, dabei ein
                                    besseres Verständnis über die Gewebsbildung von gesunden Zellen mithilfe eines
                                    zellulären Automaten zu bekommen. Zum Beispiel bei der Embryonalbildung ist es immer
                                    noch nicht vollständig verstanden, warum Zellen verschiedenen Typs sich trennen und
                                    Zellen gleichen Typs Gruppen und schließlich ein Organ bilden. Zum anderen ist es
                                    mein Ziel, einen Beitrag zur Verbesserung der Strahlentherpie von Tumoren zu
                                    leisten. Mein Ansatz ist es, die Rotationssymetrie von 3D Tumorspheroids zu nutzen,
                                    um ein 1D Differentialgleichungssystem aufzustellen.
                                </>
                            ),
                            period: '11/2020 - Heute',
                        },
                        {
                            degree: 'Master of Science',
                            headline: 'HTW Dresden',
                            paragraph: (
                                <>
                                    Mein Masterstudium an der HTW Dresden habe ich in der Fachrichtung Angewandte
                                    Informatik mit der Vertiefung Data Science abgeschlossen. In dieser Studienrichtung
                                    wurden auch Themengebiete in Richtung Statistik, Simulation und machinelle
                                    Lernverfahren tiefergehend abgedeckt, weshalb ich letztlich in meiner Masterarbeit
                                    mit dem Thema &quot;Entmischen sich Zellen genauso wie Öl und Wasser: Ein
                                    Phasenfeld-Ansatz&quot; ein mathematisches Model zur Simulation von Phasenfelder,
                                    basierend auf den der Cahn-Hilliard Gleichungen, untersucht und entwickelt habe.
                                </>
                            ),
                            period: '10/2018 - 10/2020',
                        },
                        {
                            degree: 'Bachelor of Engineering',
                            headline: 'BA Sachsen',
                            paragraph: (
                                <>
                                    Während meines dualen Studiums an der BA Dresden der Fachrichtung
                                    Informationstechnik erhielt ich einen tiefergehenden Einblick in
                                    informationstechnologische und elektrotechnische Fächer. Während der Praxisphasen
                                    arbeitete ich Vollzeit für die Kubus IT, wo ich innerhalb verschiedener Bereiche die
                                    unterschiedlichsten Themengebiete der Informatik praktisch kennenlernte.
                                </>
                            ),
                            period: '10/2015 - 09/2018',
                        },
                        {
                            degree: 'Abitur',
                            headline: 'Martin-Andersen-Nexö Gymnasium',
                            paragraph: (
                                <>
                                    Meine Gymnasialzeit habe ich auf dem Martin-Andersen-Nexö Gymnasium in Dresden
                                    verbracht. Hier erlernte ich meine allgemeine Hochschulreife mit den
                                    Leistungskursen Mathemtik, Physik und Chemie. Zusätzlich wählte ich das Fach
                                    Informatik, da ich bereits früh in diesem Bereich eine große Begeisterung empfand.
                                    Da mich aber auch das wissenschaftliche Arbeiten interessierte, entschied ich mich,
                                    eine BeLL (Besondere Lernleistung; wissenschaftliche Arbeit mit Verteidigung), mit
                                    Hilfe des Fraunhofer IVI, in mein Abitur einzubringen.
                                </>
                            ),
                            period: '08/2007 - 07/2015',
                        },
                    ],
                },
                work: {
                    title: 'Arbeit',
                    steps: [
                        {
                            position: 'Wissenschaftlicher Mitarbeiter',
                            headline: 'HTW Dresden',
                            paragraph: (
                                <>
                                    In der{' '}
                                    <a
                                        href="https://www.htw-dresden.de/hochschule/fakultaeten/info-math/forschung/zellbasierte-analyse-in-der-systemmedizin"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        DataMedAssist
                                    </a>{' '}
                                    Forschungsgruppe bin ich Teil eines interdisziplinären Teams bestehend aus
                                    graduierten Mathematikern, Informatikern und Biologen. Gemeinsam wollen wir die
                                    Forschung auf dem Gebiet der zellbasierten Analyse im Bereich der Systemmedizin
                                    vorantreiben. Zugleich unterstütze ich die Universität in der Lehre. Teile meiner
                                    Forschungsergebnisse fließen in meine Dissertation ein, die ich parallel dazu
                                    bearbeite.
                                </>
                            ),
                            period: '03/2020 - Heute',
                        },
                        {
                            position: 'Studentische Hilfskraft',
                            headline: 'HTW Dresden',
                            paragraph: (
                                <>
                                    Während meiner Zeit in der Forschungsgruppe von Prof. Hans-Joachim Böhme half ich
                                    dabei, verschiedene Modell für Speech-To-Text für die deutsche Sprache zu
                                    evaluieren. Es war eine spannende Zeit, in der ich meine Fähigkeiten und Wissen auf
                                    dem Themenbereich des machinellen Lernens weiterentwickeln konnte. Schlussendlich
                                    haben meine Erfahrungen und Begeisterung für das Thema dazu geführt, dass ich mir
                                    privat ein eigenes Smart Home mit Sprachsteuerung aufgebaut habe, wobei die
                                    Speech-To-Text Funktion offline fähig ist.
                                </>
                            ),
                            period: '02/2019 - 02/2020',
                        },
                        {
                            position: 'Studentische Hilfskraft',
                            headline: 'Fraunhofer IVI',
                            paragraph: (
                                <>
                                    Am Fraunhofer IVI war ich Teil eines Forschungsprojekts, bei welchem ich
                                    verschiedene Ansätze zum Aufsetzen eines Konfigurationsmanagement-Systems sowie der
                                    sicheren Ablage von geteilten Geheimnissen innerhalb eines Systems evaluierte.
                                    Letztlich setzte ich auch innerhalb der gegebenen Infrastruktur eine Vault zusammen
                                    mit Spring auf, welches in dem Projekt zur Authentifizierung genutzt wurde.
                                </>
                            ),
                            period: '10/2018 - 02/2019',
                        },
                        {
                            position: 'Dualer Student',
                            headline: 'Kubus IT',
                            paragraph: (
                                <>
                                    Die kubusIT ist der IT-Dienstleister für die AOK PLUS und Bayern. Infolgde dessen
                                    war es mir möglich, einen breiten Einstieg in die Welt der Informatik zu erhalten
                                    und viele Themenbereich praktisch zu erleben. Kernpunkte meiner Arbeit waren
                                    Datenbanken (MSDB), Firewalls, IT-Sicherheit und viele weitere.
                                </>
                            ),
                            period: '10/2015 - 09/2018',
                        },
                        {
                            position: 'Praktikant',
                            headline: 'Kubus IT',
                            paragraph: (
                                <>
                                    In meinem Pflichtpraktikum in der 10. Klasse, wählte ich bewusst ein IT-Unternehmen,
                                    um schon jetzt meinen Drang vollkommen in die IT-Welt abzutauchen, näher zu kommen.
                                    Dabei konnte ich meinen Wunsch, tiefer in die Informatik anzusteigen, nur verstärkt
                                    werden.
                                </>
                            ),
                            period: '04/2011',
                        },
                    ],
                },
            },
            contact: {
                title: 'Kontakt',
                requiredError: 'Diese Eingabe ist erforderlich.',
                email: {
                    label: 'E-Mail',
                    placeholder: 'Ihre E-Mail *',
                    wrongFormatError: 'Bitte geben Sie eine valide E-Mail Adresse ein!',
                },
                message: {
                    label: 'Nachricht',
                    placeholder: 'Ihre Nachricht... *',
                },
                name: {
                    label: 'Name',
                    placeholder: 'Ihr Name *',
                },
                privacyPolicy: 'Ich akzeptiere die Datenschutzbestimmungen.',
                submitButtonLabel: 'Absenden',
            },
            projects: {
                title: 'Projekte & Publikationen',
                sections: [
                    {
                        steps: [
                            {
                                content: (
                                    <>
					Dieses Projekt lädt Ihre Transaktionsrechnungen und berechnet Ihre individuellen 
					Portfoliostatistiken, die Performance und vergleicht sie mit Ihrer persönlichen 
					Aktienauswahl. Derzeit unterstützte Handelsplattformen sind Trade Republic, 
					Bison, und scalable Capital (teilweise getestet)
                                    </>
                                ),
                                headline: 'Portfolio Analysis',
                                icon: 'finance',
                                iconText: '',
                                links: [
                                    {
                                        name: 'Link to GitLab',
                                        href: 'https://gitlab.florian-franke.eu/florian/portfolioanalysis',
                                    },
                                ],
                                tags: ['python', 'REST API'],
                            },
                            {
                                content: (
                                    <>
                                        Dieses Projekt umfasst eine Anwendung, die auf der Grundlage realer Börsendaten
                                        Handlungsstrategien für den Aktienhandel empfiehlt. Diese werden über
                                        Chat-Nachrichten von einem Telegram-Bot veröffentlicht, der abonniert werden
                                        kann.
                                    </>
                                ),
                                headline: 'autoTrade - Trading Consultant Bot',
                                icon: 'finance',
                                iconText: '',
                                links: [
                                    {
                                        name: 'Link zu GitLab',
                                        href: 'https://gitlab.florian-franke.eu/florian/autotrade',
                                    },
                                ],
                                tags: ['python', 'REST API'],
                            },
                            {
                                content: (
                                    <>
                                        In diesem IoT-Projekt habe ich einen Sprachassistenten entwickelt, der auf einem
                                        Raspberry Pi läuft und Sprachbefehle annimmt. Diese werden dann in einer
                                        serverseitigen Python-Software ausgewertet und entsprechende Aktionen ausgelöst,
                                        wie z. B. das Einschalten eines Lichts oder das Setzen eines Timers. Der
                                        Sprachassistent ist auch in der Lage, entsprechende Sprachausgaben zu liefern.
                                        Das Besondere:  Das System ist, abgesehen von einigen Skills, völlig unabhänig vom
                                        Internet betreibbar.
                                    </>
                                ),
                                headline: 'Home Assistent - Offline Capable Home Assistant',
                                icon: 'iot',
                                iconText: '',
                                links: [
                                    {
                                        name: 'Link zu GitLab',
                                        href: 'https://gitlab.florian-franke.eu/florian/home-assistent',
                                    },
                                ],
                                tags: ['python', 'MQTT', 'IoT', 'Smart Home'],
                            },
                            {
                                content: (
                                    <>
                                        Dies ist ein Werkzeug, das nach Eingabe verschiedener Assembler-Befehle deren
                                        Pipelining im Prozessor visualisiert. Dieses Projekt stammt aus meiner Zeit an
                                        der BA-Dresden, wo uns der Prozess des Pipelingings der Befehle in Assembler
                                        beigebracht wurde. Hier hatte ich so viel Spaß an Assembler und der Theorie,
                                        dass ich sie gleich durch ein kleines Projekt praktisch untermauern kann.
                                    </>
                                ),
                                headline: 'asspipe - Automated Assembler Pipeline',
                                icon: 'flow',
                                iconText: '',
                                links: [
                                    {
                                        name: 'Link zu GitLab',
                                        href: 'https://gitlab.florian-franke.eu/florian/asspipe',
                                    },
                                ],
                                tags: ['python', 'Assembler', 'Flask'],
                            },
                        ],
                        title: 'Projekte',
                    },
                    {
                        steps: [
                            {
                                content: (
                                    <>
                                        <p>
                                            Florian Franke, Sebastian Aland, Hans-Joachim Böhme, Anja Voss-Böhme,
                                            Steffen Lange
                                        </p>
                                        <p>November 2021</p>
                                    </>
                                ),
                                headline: 'Is cell segregation like oil and water: asymptotic versus transitory regime',
                                icon: 'book',
                                iconText: 'Article',
                                links: [{ name: 'Link zum Magazin', href: 'https://arxiv.org/abs/2109.00364' }],
                                tags: [
                                    'under peer review',
                                    'Cahn-Hilliard',
                                    'Cellular Automaton',
                                    'Cells',
                                    'segregation',
                                    'Python',
                                    'C++',
                                ],
                            },
                            {
                                content: (
                                    <>
                                        <p>Nadja Adam, Florian Franke, Sebastian Aland</p>
                                        <p>July 2020</p>
                                    </>
                                ),
                                headline:
                                    'A Simple Parallel Solution Method for the Navier-Stokes Cahn-Hilliard Equations',
                                icon: 'book',
                                iconText: 'Article',
                                links: [{ name: 'Link zum Magazin', href: 'https://doi.org/10.3390/math8081224' }],
                                tags: ['Navier-Stokes', 'Cahn-Hilliard', 'C++', 'OpenMP', 'MPI'],
                            },
                            {
                                content: 'September 2020',
                                headline: 'Entmischen sich Zellen genauso wie Öl und Wasser: Ein Phasenfeld-Ansatz',
                                icon: 'book',
                                iconText: 'Master-arbeit',
                                links: [
                                    {
                                        name: 'Download',
                                        href: 'https://cloud.florian-franke.eu/index.php/s/zd7SRdKWSgf2ixp',
                                    },
                                ],
                                tags: ['Cellular Automaton', 'Navier-Stokes', 'Cahn-Hilliard', 'C++', 'python'],
                            },
                            {
                                content: 'July 2018',
                                headline:
                                    'Prototypische Entwicklung eines modularen Frameworks zum automatisierten Anlegen einer Demilitarisierten Zone in einem mittelständischen Unternehmen',
                                icon: 'book',
                                iconText: 'Bachelor-arbeit',
                                links: [],
                                tags: ['Firewall', 'Powershell', 'Networking'],
                            },
                        ],
                        title: 'Publikationen',
                    },
                ],
            },
            snackbar: {
                errors: {
                    formDataSubmissionFailed: 'Fehler beim Senden Ihrer Daten. Bitte versuchen Sie es später erneut.',
                },
                successes: {
                    formDataSubmissionSucceeded:
                        'Ihre Nachricht wurde übermittelt. Sie erhalten in Kürze eine Bestätigung per E-Mail.',
                },
            },
            footer: {
                links: [],
            },
            dataProtection: {
                back: 'Zurück',
                title: 'Datenschutz',
                content: (
                    <>
                        <h2>Datenschutzerklärung</h2>
                        <p>
                            Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im
                            Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und
                            nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen
                            Leistungen, verarbeitet.
                        </p>{' '}
                        <p>
                            Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung
                            (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe
                            automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im
                            Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation,
                            das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die
                            Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
                            Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die
                            Vernichtung.
                        </p>{' '}
                        <p>
                            Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art,
                            Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit
                            wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
                            entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken
                            sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch
                            Dritte Daten in wiederum eigener Verantwortung verarbeiten.
                        </p>{' '}
                        <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>{' '}
                        <p>
                            I. Informationen über uns als Verantwortliche
                            <br />
                            II. Rechte der Nutzer und Betroffenen
                            <br />
                            III. Informationen zur Datenverarbeitung
                        </p>{' '}
                        <h3>I. Informationen über uns als Verantwortliche</h3>{' '}
                        <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
                        <p>
                            <span>
                                Florian Franke
                                <br />
                                mail@florian-franke.eu
                                <br />
                                Deutschland
                            </span>
                        </p>{' '}
                        <h3>II. Rechte der Nutzer und Betroffenen</h3>{' '}
                        <p>
                            Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und
                            Betroffenen das Recht
                        </p>{' '}
                        <ul>
                            {' '}
                            <li>
                                auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die
                                verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf
                                Kopien der Daten (vgl. auch Art. 15 DSGVO);
                            </li>{' '}
                            <li>
                                auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl.
                                auch Art. 16 DSGVO);
                            </li>{' '}
                            <li>
                                auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder,
                                alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich
                                ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;
                            </li>{' '}
                            <li>
                                auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf
                                Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);
                            </li>{' '}
                            <li>
                                auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie
                                betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche
                                Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).
                            </li>{' '}
                        </ul>{' '}
                        <p>
                            Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten
                            durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von
                            Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18
                            DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese
                            Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet
                            dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.
                        </p>{' '}
                        <p>
                            <strong>
                                Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch
                                gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den
                                Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere
                                ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.
                            </strong>
                        </p>{' '}
                        <h3>III. Informationen zur Datenverarbeitung</h3>{' '}
                        <p>
                            Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder
                            gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine
                            gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden
                            Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.
                        </p>{' '}
                        <h4>Serverdaten</h4>{' '}
                        <p>
                            Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen
                            Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren
                            Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version
                            Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren
                            Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts,
                            die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des
                            Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.
                        </p>{' '}
                        <p>
                            Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit
                            anderen Daten von Ihnen.
                        </p>{' '}
                        <p>
                            Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser
                            berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit
                            unseres Internetauftritts.
                        </p>{' '}
                        <p>
                            Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere
                            Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur
                            endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.
                        </p>{' '}
                        <h4>Cookies</h4> <h5>a) Sitzungs-Cookies/Session-Cookies</h5>{' '}
                        <p>
                            Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien
                            oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf
                            Ihrem Endgerät ablegt und gespeichert werden. Durch diese Cookies werden im individuellen
                            Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder
                            Standortdaten oder Ihre IP-Adresse, verarbeitet. &nbsp;
                        </p>{' '}
                        <p>
                            Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und
                            sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in
                            unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion ermöglicht.
                        </p>{' '}
                        <p>
                            Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies
                            Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.
                        </p>{' '}
                        <p>
                            Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt
                            unser berechtigtes Interesse in der Verbesserung der Funktionalität unseres
                            Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.
                        </p>{' '}
                        <p>Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>{' '}
                        <h5>b) Drittanbieter-Cookies</h5>{' '}
                        <p>
                            Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit
                            denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres
                            Internetauftritts zusammenarbeiten, verwendet.
                        </p>{' '}
                        <p>
                            Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der
                            Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden
                            Informationen.
                        </p>{' '}
                        <h5>c) Beseitigungsmöglichkeit</h5>{' '}
                        <p>
                            Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers
                            verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit
                            löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret
                            genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder
                            Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support.
                            Bei sog. Flash-Cookies kann die Verarbeitung allerdings nicht über die Einstellungen des
                            Browsers unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres
                            Flash-Players ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen von
                            Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die
                            Hilfefunktion oder Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw.
                            Benutzer-Support.
                        </p>{' '}
                        <p>
                            Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings
                            dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich
                            nutzbar sind.
                        </p>{' '}
                        <h4>Kontaktanfragen / Kontaktmöglichkeit</h4>{' '}
                        <p>
                            Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von
                            Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur
                            Bearbeitung und Beantwortung Ihre Anfrage erforderlich - ohne deren Bereitstellung können
                            wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.
                        </p>{' '}
                        <p>Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>{' '}
                        <p>
                            Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der
                            Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich
                            etwaig anschließenden Vertragsabwicklung.
                        </p>{' '}
                        <h4>Google Analytics</h4>
                        <p>
                            In unserem Internetauftritt setzen wir Google Analytics ein. Hierbei handelt es sich um
                            einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
                            Irland, nachfolgend nur „Google“ genannt.
                        </p>
                        <p>
                            Der Dienst Google Analytics dient zur Analyse des Nutzungsverhaltens unseres
                            Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes
                            Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres
                            Internetauftritts.
                        </p>
                        <p>
                            Nutzungs- und nutzerbezogene Informationen, wie bspw. IP-Adresse, Ort, Zeit oder Häufigkeit
                            des Besuchs unseres Internetauftritts, werden dabei an einen Server von Google in den USA
                            übertragen und dort gespeichert. Allerdings nutzen wir Google Analytics mit der sog.
                            Anonymisierungsfunktion. Durch diese Funktion kürzt Google die IP-Adresse schon innerhalb
                            der EU bzw. des EWR.
                        </p>{' '}
                        <p>
                            Die so erhobenen Daten werden wiederum von Google genutzt, um uns eine Auswertung über den
                            Besuch unseres Internetauftritts sowie über die dortigen Nutzungsaktivitäten zur Verfügung
                            zu stellen. Auch können diese Daten genutzt werden, um weitere Dienstleistungen zu
                            erbringen, die mit der Nutzung unseres Internetauftritts und der Nutzung des Internets
                            zusammenhängen.
                        </p>{' '}
                        <p>
                            Google gibt an, Ihre IP-Adresse nicht mit anderen Daten zu verbinden. Zudem hält Google
                            unter
                        </p>{' '}
                        <p>
                            <a
                                href="https://www.google.com/intl/de/policies/privacy/partners"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://www.google.com/intl/de/policies/privacy/partners
                            </a>
                        </p>{' '}
                        <p>
                            weitere datenschutzrechtliche Informationen für Sie bereit, so bspw. auch zu den
                            Möglichkeiten, die Datennutzung zu unterbinden.
                        </p>{' '}
                        <p>Zudem bietet Google unter</p>{' '}
                        <p>
                            <a
                                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://tools.google.com/dlpage/gaoptout?hl=de
                            </a>
                        </p>{' '}
                        <p>
                            ein sog. Deaktivierungs-Add-on nebst weiteren Informationen hierzu an. Dieses Add-on lässt
                            sich mit den gängigen Internet-Browsern installieren und bietet Ihnen weitergehende
                            Kontrollmöglichkeit über die Daten, die Google bei Aufruf unseres Internetauftritts erfasst.
                            Dabei teilt das Add-on dem JavaScript (ga.js) von Google Analytics mit, dass Informationen
                            zum Besuch unseres Internetauftritts nicht an Google Analytics übermittelt werden sollen.
                            Dies verhindert aber nicht, dass Informationen an uns oder an andere Webanalysedienste
                            übermittelt werden. Ob und welche weiteren Webanalysedienste von uns eingesetzt werden,
                            erfahren Sie natürlich ebenfalls in dieser Datenschutzerklärung.
                        </p>{' '}
                        <h4>Google reCAPTCHA</h4>{' '}
                        <p>
                            In unserem Internetauftritt setzen wir Google reCAPTCHA zur Überprüfung und Vermeidung von
                            Interaktionen auf unserer Internetseite durch automatisierte Zugriffe, bspw. durch sog.
                            Bots, ein. Es handelt sich hierbei um einen Dienst der Google Ireland Limited, Gordon House,
                            Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“ genannt.
                        </p>{' '}
                        <p>
                            Durch diesen Dienst kann Google ermitteln, von welcher Webseite eine Anfrage gesendet wird
                            sowie von welcher IP-Adresse aus Sie die sog. reCAPTCHA-Eingabebox verwenden. Neben Ihrer
                            IP-Adresse werden womöglich noch weitere Informationen durch Google erfasst, die für das
                            Angebot und die Gewährleistung dieses Dienstes notwendig sind.&nbsp;&nbsp;&nbsp;
                        </p>{' '}
                        <p>
                            Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der
                            Sicherheit unseres Internetauftritts sowie in der Abwehr unerwünschter, automatisierter
                            Zugriffe in Form von Spam o.ä..
                        </p>{' '}
                        <p>Google bietet unter</p>{' '}
                        <p>
                            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                                https://policies.google.com/privacy
                            </a>
                        </p>{' '}
                        <p>weitergehende Informationen zu dem allgemeinen Umgang mit Ihren Nutzerdaten an.</p>{' '}
                        <h4>Google Fonts</h4>{' '}
                        <p>
                            In unserem Internetauftritt setzen wir Google Fonts zur Darstellung externer Schriftarten
                            ein. Es handelt sich hierbei um einen Dienst der Google Ireland Limited, Gordon House,
                            Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“ genannt.
                        </p>{' '}
                        <p>
                            Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird bei
                            Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in den USA aufgebaut.
                        </p>{' '}
                        <p>
                            Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der
                            Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.
                        </p>{' '}
                        <p>
                            Durch die bei Aufruf unseres Internetauftritts hergestellte Verbindung zu Google kann Google
                            ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die
                            Darstellung der Schrift zu übermitteln ist.
                        </p>{' '}
                        <p>Google bietet unter</p>{' '}
                        <p>
                            <a
                                href="https://adssettings.google.com/authenticated"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://adssettings.google.com/authenticated
                            </a>
                        </p>{' '}
                        <p>
                            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                                https://policies.google.com/privacy
                            </a>
                        </p>{' '}
                        <p>
                            weitere Informationen an und zwar insbesondere zu den Möglichkeiten der Unterbindung der
                            Datennutzung.
                        </p>{' '}
                        <p>
                            <a
                                href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Muster-Datenschutzerklärung
                            </a>{' '}
                            der{' '}
                            <a
                                href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Anwaltskanzlei Weiß &amp; Partner
                            </a>
                        </p>
                    </>
                ),
            },
        },
    },
};
