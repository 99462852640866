export type Level = 'primary' | 'secondary' | 'tertiary' | 'light';

export const PALETTE: { [key in Level]: string } = {
    primary: '#153E5B', // main color
    secondary: '#3F7DA6', // secondary color
    tertiary: '#EC5241', // contrast
    light: '#BED6E5', // main font color
};

export const APP_NAME = 'Florian Franke';

export const PERSONAL_DATA = {
    birthDate: '1997-01-03',
    languages: ['german', 'english'],
    leisures: ['programming', 'fin-tech', 'administration of a linux server'],
    programming: ['python', 'C++', 'SQL', 'php', 'Java'],
    libraries: ['numpy', 'numba', 'openMP', 'MPI'],
};

export const ANALYTICS_ELEMENT: JSX.Element | null = <></>; // TODO
